.animate-bounce {
  animation: bounce 3s infinite ease-in-out;
}
.animate-bounces {
  animation: bounce 2.8s infinite ease-in-out;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(
      -30px
    ); /* Adjust this value for the desired bounce height */
  }
}
